import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { buildCourseImageURL } from 'utils/buildCourseImageURL';
import { getUserId } from 'components/auth/Authenticated';
import useLessonProgress from 'hooks/useLessonProgress';
import CardLevelIcon from 'components/common/miscellaneous/CardLevelIcon';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Content from 'api/Content';

const ProgressCourseCard = ({ course, showprogressbar }) => {
    const user = getUserId();

    const { t } = useTranslation();
    const { id: courseId, title, progress = 0, level = "Beginner", tasks = 0 } = course;
    const imageUrl = buildCourseImageURL(courseId);
    const navigate = useNavigate();
    
    const { lessonIdToNavigate, buttonText } = useLessonProgress(courseId, user?.id);
    const [lessonCount, setLessonCount] = useState(0);

    useEffect(() => {
        const fetchLessonCount = async () => {
            try {
                const data = await Content.get(`/courses/${courseId}/lessons`);
                const totalLessons = (data.sections || []).reduce(
                    (acc, section) => acc + (section.lessons?.length || 0),
                    0
                );
                setLessonCount(totalLessons);
            } catch (error) {
                console.error("Error fetching lesson count:", error);
            }
        };

        fetchLessonCount();
    }, [courseId]);

    const handleButtonClick = () => {
        if (lessonIdToNavigate) {
            navigate(`/courses/${courseId}/${lessonIdToNavigate}`);
        } else {
            console.warn('No valid lesson ID found to navigate.');
        }
    };

    const handleCardClick = () => {
        navigate(`/courses/${courseId}`);
    };

    return (
        <div
            className="card shadow-sm course-card"
            style={{ borderRadius: '2px', overflow: 'hidden', cursor: 'pointer' }}
            onClick={handleCardClick}
        >
            <img
                src={imageUrl}
                className="card-img-top"
                alt={title}
                style={{ height: '180px', objectFit: 'cover', borderTopLeftRadius: "2px", borderTopRightRadius: "2px" }}
            />
            <div className="card-body">
                <h3 className="card-title mb-3">{title}</h3>
                
                {showprogressbar && (
                    <>
                        <div className="text-secondary small">
                            {Math.round(progress)}% {t('completed')}
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <div className="progress" style={{ width: '40%', height: '8px', borderRadius: '4px', marginRight: '10px' }}>
                                <div
                                    className="progress-bar bg-success"
                                    role="progressbar"
                                    style={{ width: `${Math.round(progress)}%` }}
                                    aria-valuenow={Math.round(progress)}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            <button 
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    handleButtonClick();
                                }}
                                className="btn btn-outline-primary btn-sm ms-auto"
                            >
                                {buttonText} →
                            </button>
                        </div>
                    </>
                )}
            </div>
            <div className="card-footer d-flex align-items-center py-2">
                <span className="d-flex align-items-center me-1">
                    <CardLevelIcon level={level} /> 
                    <span className="text-muted ms-1" style={{ fontSize: "14px" }}>{level}</span>
                </span>
                <span className="d-flex align-items-center ms-auto">
                    <i className="bi bi-journal-text me-1" style={{ fontSize: "14px" }}></i>
                    <span className="text-muted" style={{ fontSize: "14px" }}>{lessonCount} {t('lessons')}</span>
                </span>
            </div>
        </div>
    );
};

export default ProgressCourseCard;