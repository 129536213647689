import { useEffect } from "react";
import mixpanel from "analytics/mixpanel";

export const useDurationTracker = (pageName) => {
  useEffect(() => {
    const start = Date.now();
    let focusTime = 0;
    let focusStart = Date.now();

    const handleVisibilityChange = () => {
      if (document.hidden) focusTime += Date.now() - focusStart;
      else focusStart = Date.now();
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      const totalDuration = (Date.now() - start) / 1000;
      if (!document.hidden) focusTime += Date.now() - focusStart; 

      mixpanel.track_pageview({
        page: pageName,
        view_duration_seconds: totalDuration,
        focus_duration_seconds: focusTime / 1000,
      });

      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
};