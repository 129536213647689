import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertCircle } from "lucide-react";
import { Container, Form, Button, Card, InputGroup } from "react-bootstrap";
import API from "api/API";

const CompleteProfile = ({ user, onProfileComplete }) => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 60 }, (_, i) => currentYear - 7 - i);

  const [formData, setFormData] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    phone: user?.phone || "+998",
    yearOfBirth: user?.yearOfBirth || "",
  });

  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationRules = {
    firstName: (value) => (!value.trim() ? t("this_field_is_required") : ""),
    lastName: (value) => (!value.trim() ? t("this_field_is_required") : ""),
    phone: (value) => {
      if (!value.trim()) return t("this_field_is_required");
      if (value.replace(/\D/g, "").length < 12) return t("invalid_phone_number");
      return "";
    },
    yearOfBirth: (value) => (!value ? t("this_field_is_required") : ""),
  };

  const handleChange = ({ target: { name, value } }) => {
    if ((name === "firstName" || name === "lastName") && value.length > 52) return;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (event) => {
    let value = event.target.value;

    value = value.replace(/[^0-9+]/g, "");

    if (!value.startsWith("+998")) {
      value = "+998";
    }

    let phoneNumber = value.slice(4).replace(/\D/g, "").slice(0, 9);
    value = "+998" + phoneNumber;

    setFormData((prev) => ({ ...prev, phone: value }));
  };

  const validate = () => {
    let newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      const errorMessage = validationRules[key](value);
      if (errorMessage) newErrors[key] = errorMessage;
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!user?.id) {
      alert(t("error_user_id_missing"));
      return;
    }

    setShowErrors(true);
    if (!validate()) return;

    setLoading(true);
    try {
      const payload = { id: user.id, ...formData, yearOfBirth: parseInt(formData.yearOfBirth, 10) };

      const profile = await API.put(`/users/api/v1/users/${user.id}`, payload);

      if (onProfileComplete) {
        onProfileComplete(profile);
      }
    } catch (error) {
      alert(t("failed_to_update_profile"));
    } finally {
      setLoading(false);
    }
  };

  const formFields = [
    { label: t("first_name"), name: "firstName", type: "text" },
    { label: t("last_name"), name: "lastName", type: "text" },
    { label: t("phone"), name: "phone", type: "text" },
    { label: t("year_of_birth"), name: "yearOfBirth", type: "select" },
  ];

  const renderInputField = ({ label, name, type }) => (
    <Form.Group key={name} className="mb-3">
      <Form.Label>{label}</Form.Label>
      {type === "select" ? (
        <InputGroup>
          <Form.Control
            as="select"
            name={name}
            value={formData[name]}
            onChange={handleChange}
            className={`form-select ${showErrors && errors[name] ? "border-danger" : ""}`} 
          >
            <option value=""></option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Form.Control>
        </InputGroup>
      ) : (
        <Form.Control
          type={type}
          name={name}
          value={name === "phone" ? formData.phone : formData[name]}
          onChange={name === "phone" ? handlePhoneChange : handleChange}
          maxLength={name === "firstName" || name === "lastName" ? 52 : undefined}
          className={showErrors && errors[name] ? "border-danger" : ""}
        />
      )}
      {showErrors && errors[name] && (
        <div className="text-danger small mt-1 d-flex align-items-center">
          <AlertCircle className="me-1" /> {errors[name]}
        </div>
      )}
    </Form.Group>
  );

  return (
    <Container className="py-5 d-flex justify-content-center mt-10">
      <Card className="p-4 shadow" style={{ maxWidth: "600px", width: "100%" }}>
        <h2 className="mb-4 text-center">{t("complete_profile")}</h2>
        <p className="text-muted text-center">{t("fill_missing_details")}</p>
        <Form>
          {formFields.map(renderInputField)}
          <div className="d-flex gap-2 justify-content-center">
            <Button variant="primary" onClick={handleSubmit} disabled={loading} className="w-100 py-2">
              {loading ? t("saving") : t("save")}
            </Button>
          </div>
        </Form>
      </Card>
    </Container>
  );
};

export default CompleteProfile;