import React, { useState, useEffect } from 'react';
import TelegramLogin from 'components/auth/telegram/Telegram';
import Auth0Login from 'components/auth/auth0/Auth0';
import AuthPage from './AuthPage';
import CompleteProfile from 'components/user-profile/CompleteProfile';
import Cookies from 'js-cookie';
import { useSessionStorage } from 'react-use-storage';
import mixpanelInstance from 'analytics/mixpanel';
import { isProfileComplete } from 'components/user-profile/isProfileComplete';
import API from 'api/API';
import { useTranslation } from "react-i18next";

const userIDKey = 'user_id';
const accessTokenKey = 'access_token';
const authMethodKey = 'auth_method';

const Authenticated = ({ Component: ProtectedComponent }) => {
  const { t } = useTranslation();

  const [user, setUser] = useState(getUserId());
  const [method, setMethod] = useSessionStorage(authMethodKey);
  const [loading, setLoading] = useState(true);

  if (user && !isProfileComplete(user)) {
    // Fetch the existing profile
    API.get(`/users/api/v1/users/${user.id}`)
    .then((profile) => {
      setUser(profile);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching profile:', error);
      setLoading(false);
    });

    // Wait for the profile to be fetched
    if (loading) {
      return <div>{t('loggingIn')}</div>;
    }

    // If profile is not complete, show the complete profile page
    const onProfileComplete = (profile) => {
      setUser(profile);
    };
    return <CompleteProfile user={user} onProfileComplete={onProfileComplete} />;
  }

  // If user is logged in, show the protected component
  if (user) {
    return <ProtectedComponent />;
  }

  // If user is not logged in, initiate the login process
  const onSuccess = async (user, accessToken) => {
    // 6.90 days because the cookie for the access token expires in 7 days.
    // Access token cookie is set by the backend server on successful login regardless of the login method.
    const expires = 6.90;
    Cookies.set(userIDKey, user.id, { expires: expires, sameSite: 'strict', secure: true, path: '/' });
    localStorage.setItem(accessTokenKey, accessToken);

    // Identify the user in Mixpanel
    mixpanelInstance.identify(user.id);

    setUser(user);
    setMethod(null);
  };

  const onFailure = (error) => {
    console.error('Error logging in:', error);
    setMethod(null);
  };


  if (method === 'telegram') {
    return <TelegramLogin onSuccess={onSuccess} onFailure={onFailure} />;
  } else if (method === 'auth0') {
    return <Auth0Login onSuccess={onSuccess} onFailure={onFailure} />;
  }

  return <AuthPage setMethod={setMethod} />;
};

const getUserId = () => {
  const userID = Cookies.get('user_id');
  if (userID) {
    return { id: userID };
  }
  return null;
};

const getAccessToken = () => {
  return localStorage.getItem(accessTokenKey);
};

const logout = () => {
  // Reset Mixpanel user
  mixpanelInstance.reset();

  Cookies.remove('user_id');
  Cookies.remove('telegram_token');
  Cookies.remove('bearer_token');
  localStorage.removeItem(accessTokenKey);
};

export default Authenticated;
export { getUserId, getAccessToken, logout };