import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LessonNavigation = ({
  prevLesson,
  nextLesson,
  isLessonCompleted,
  toggleCompletion,
  handlePrevLesson,
  handleNextLesson,
  isLessonLocked,
  user
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4 mb-4">
      {prevLesson ? (
        <Button
          variant="secondary"
          onClick={handlePrevLesson}
          className="mb-2 mb-md-0 w-100 w-md-auto"
        >
          {t('previous_lesson')}
        </Button>
      ) : (
        <div className="mb-2 mb-md-0"></div>
      )}

      <div className="d-flex flex-column flex-md-row align-items-center w-100 w-md-auto">
        {user && (
          <Form.Check
            type="checkbox"
            label={t('lesson_completed')}
            checked={isLessonCompleted}
            onChange={(e) => toggleCompletion(e.target.checked)}
            disabled={isLessonLocked}
            className="mb-2 mb-md-0 me-md-4"
          />
        )}

        {nextLesson && (
          <Button
            variant="primary"
            onClick={handleNextLesson}
            className="w-100 w-md-auto"
          >
            {t('next_lesson')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LessonNavigation;