import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import ScrollToTop from 'components/layouts/ScrollToTop';
import AllRoutes from 'routes/AllRoutes';
import { useEffect } from 'react';
import { initializeAnalytics, trackPageView } from './analytics/ga4'; 
import { initializeFacebookPixel, trackFacebookEvent } from './analytics/facebookPixel';
import mixpanelInstance from 'analytics/mixpanel'; 
import { getUserId } from 'components/auth/Authenticated';
import './i18n';

// Import required stylesheets
import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

function App() {
  useEffect(() => {
    initializeAnalytics(); 
    initializeFacebookPixel();
  }, []);

  return (
    <Router>
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_API_BASE_URL,
        }}
      >
        <ScrollToTop />
        <AllRoutes />
      </Auth0ProviderWithRedirectCallback>
    </Router>
  );
}

export default App;