import useAuthTokens from './useAuthTokens';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Auth0Login = ({ onSuccess, onFailure }) => {
    const { t } = useTranslation(); 
    const { idToken } = useAuthTokens();
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    if (isAuthenticated && !idToken) {
        return <div>{t('loggingIn')}</div>;
    }

    if (idToken) {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/login`, {}, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            }
        })
            .then((res) => {
                const user = res.data;
                const accessToken = res.headers.getAuthorization();
                onSuccess(user, accessToken);
            })
            .catch((error) => {
                onFailure(error);
            });

        return <div>{t('loggingIn')}</div>;
    }

    loginWithRedirect({
        appState: { returnTo: `${window.location.pathname}` },
        authorizationParams: {
            audience: process.env.REACT_APP_API_BASE_URL,
        },
    });

    return <div>{t('redirecting')}</div>;
};

export default Auth0Login;