import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PlanCard from 'components/pricing-page/PlanCard';
import useFetchPricingPlans from 'hooks/useFetchPricingPlans';
import useFetchSubscriptions from 'hooks/useFetchSubscriptions';
import { getUserId } from 'components/auth/Authenticated';
import mixpanel from "analytics/mixpanel";


const MONTHLY_DURATION = 30;
const THREE_MONTHLY_DURATION = 90;

const DURATION_LABELS = {
  [MONTHLY_DURATION]: "oy",
  [THREE_MONTHLY_DURATION]: "3 oy",
};

const PricingPage = () => {
  const { t } = useTranslation();
  const { plans, error } = useFetchPricingPlans();
  const [duration, setDuration] = useState(MONTHLY_DURATION);

  const user = getUserId();
  const { subscriptions, loading, error: subscriptionError } = useFetchSubscriptions(user); // Fetch subscriptions

  const durationLabel = DURATION_LABELS[duration];

  const hasActiveSubscription = subscriptions.length > 0;

  const toggleDuration = (days) => {
    setDuration(days);
  };

  useEffect(() => {
    mixpanel.track_pageview({
        "page": "Pricing Page",
    });
  }, []);

  return (
    <>
      <section style={{ backgroundColor: '#F7F6F3' }} className="pt-lg-8 pb-lg-16 pt-8 pb-12">
        <div className="container text-center my-5">
          <h1>{t('level_up_learning')}</h1>
          <p>{t('our_pricing_plans')}</p>

          <div
            className="d-inline-flex p-1 border border-primary rounded-pill bg-white"
            style={{
              fontSize: '0.875rem',
              padding: '2px',
            }}
          >
            <button
              type="button"
              onClick={() => toggleDuration(MONTHLY_DURATION)}
              className={`btn ${duration === MONTHLY_DURATION ? 'btn-primary text-white' : 'text-primary'}`}
              style={{
                borderRadius: '15px',
                padding: '4px 12px',
                fontWeight: 'bold',
                border: 'none',
                backgroundColor: duration === MONTHLY_DURATION ? '' : 'transparent',
              }}
            >
              {t('monthly')}
            </button>
            <button
              type="button"
              onClick={() => toggleDuration(THREE_MONTHLY_DURATION)}
              className={`btn ${duration === THREE_MONTHLY_DURATION ? 'btn-primary text-white' : 'text-primary'}`}
              style={{
                borderRadius: '15px',
                padding: '4px 12px',
                fontWeight: 'bold',
                border: 'none',
                backgroundColor: duration === THREE_MONTHLY_DURATION ? '' : 'transparent',
              }}
            >
              {t('three_monthly')}
            </button>
          </div>
        </div>
      </section>

      <div className="container mt-n10">
        <div className="row justify-content-center">
          {error || subscriptionError ? (
            <p>{t('error_fetching_plans')}</p>
          ) : loading ? (
            <p>{t('loading')}</p>
          ) : (
            plans.map((plan, index) => {
              const pricingOption = plan.pricingOptions.find(option => option.durationDays === duration);

              const activeSubscription = subscriptions.find((sub) => sub.planId === plan.id);

              const isActive = !!activeSubscription; 
              const expiryDate = activeSubscription?.expiresAt; 

              return (
                <div key={index} className="col-md-4 d-flex justify-content-center">
                  <PlanCard
                    plan={{ ...plan, price: pricingOption?.price }}
                    durationLabel={durationLabel}
                    durationDays={pricingOption?.durationDays}
                    isActive={isActive}
                    expiryDate={expiryDate}
                    hasActiveSubscription={hasActiveSubscription} 
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default PricingPage;