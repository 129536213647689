import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getUserId } from './Authenticated';
import { useNavigate } from 'react-router-dom';
import 'assets/css/AuthorizationPage.css';
import mixpanel from 'analytics/mixpanel';
import { useDurationTracker } from 'analytics/useDurationTracker'; 

const AuthPage = ({ setMethod }) => {
    const { t } = useTranslation(); 
    const user = getUserId();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, []);

    useDurationTracker("Authorization Page");
    
    const handleAuthClick = (method) => {
        setMethod(method);
        
        mixpanel.track('Authorization Option Selected', {
            method: method,
        });
    };

    return (
        <div className="container d-flex justify-content-center align-items-center auth-page-container-wrapper">
            <div className="auth-page-container text-center p-4 bg-white border rounded shadow">
                <p className="mb-5">{t('choose_login')}</p>
                <div className="d-grid gap-3">
                    <Button
                        className="btn btn-primary"
                        onClick={() => handleAuthClick('telegram')}
                    >
                        <i className="fab fa-telegram-plane"></i> {t('login_with_telegram')}
                    </Button>
                    <Button
                        className="btn btn-secondary"
                        onClick={() => handleAuthClick('auth0')}
                    >
                        <i className="fas fa-envelope"></i> {t('login_with_email')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AuthPage;